
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form, defineRule } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface items {
  disableButton: boolean;
  erroroldPassword: string;
  errornewPassword: string;
  errorconfirmNewPassword: string;

  aksi: string;
  idProgress: string;

  _idUser: string;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export default defineComponent({
  name: "profile",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },
  setup() {

    const name = JSON.parse(localStorage.getItem("user_account")!).name; 
    const email = JSON.parse(localStorage.getItem("user_account")!).email;

    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const ubahSandiModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      disableButton: false,
      erroroldPassword: "",
      errornewPassword: "",
      errorconfirmNewPassword: "",
      aksi: "",
      idProgress: "",

      _idUser: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });

    const confirmed = () => {
      if (items.newPassword != items.confirmNewPassword) {
        items.errorconfirmNewPassword = "Konfirmasi Kata Sandi Harus Sama";
      } else {
        items.errorconfirmNewPassword = "";
      }
    };

    const submitAdd = () => {
      if (
        items.oldPassword === "" ||
        items.newPassword === "" ||
        items.confirmNewPassword === ""
      ) {
        items.erroroldPassword = "Kata Sandi Lama Wajib Diisi";
        items.errornewPassword = "Kata Sandi Baru Wajib Diisi";
        items.errorconfirmNewPassword = "Konfirmasi Kata Sandi Wajib Diisi"
      } else if (
        items.newPassword != items.confirmNewPassword
      ) {
        items.errorconfirmNewPassword.replace("Konfirmasi Kata Sandi Wajib Diisi", "Konfirmasi Kata Sandi Harus Sama");
        // items.errorconfirmNewPassword = "Konfirmasi Kata Sandi Harus Sama";
      } else {
        items.erroroldPassword = "";
        items.errornewPassword = "";
        items.errorconfirmNewPassword = "";

        isLoading.value = true;
        
        items.disableButton = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");

        // Send login request
        ApiService.PutMethodWithoutData(
          "crmv2/main_user/user/auth/change_password/" + items._idUser + "?oldPassword=" + items.oldPassword + "&newPassword=" + items.newPassword
        )
          .then((res) => {
            toast.success("Berhasil Ubah Kata Sandi");
            submitButton.value?.removeAttribute("data-kt-indicator");
            hideModal(ubahSandiModalRef.value);
            isLoading.value = false;
          })
          .catch((e) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              router.push('/sign-in');
              isLoading.value = false;
            } else {
              toast.error(e.response.data.detail);
              items.disableButton = false;
              hideModal(ubahSandiModalRef.value);
              isLoading.value = false;
            }
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    onMounted(() => {
      const store = useStore();
      store.dispatch(Actions.VERIFY_AUTH_BANK);
      items._idUser = JSON.parse(localStorage.getItem("user_account")!).userId; 
    });

    const batalUbahSandi = () => {
      items.oldPassword = ""; 
      items.newPassword = "";
      items.confirmNewPassword = "";

      items.erroroldPassword = "";
      items.errornewPassword = "";
      items.errorconfirmNewPassword = "";
    };

    return {
      name,
      email,

      submitAdd,
      batalUbahSandi,
      onMounted,

      submitButton,
      items,
      ubahSandiModalRef,
      confirmed,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };

  },
});
